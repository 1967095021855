import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import {
	GenericAlert,
	GenericCheckInput,
	GenericDateInput,
	GenericEmailInput,
	GenericGrid,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, MenuCodes } from "views/Constants/Constant.js";
import DateHelper from "../../../../core/DateHelper";

class KeyContact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			keyContactModel: {},
			PrimaryPhoneModel: {},
			MobilePhoneModel: {},
			ServiceMapModel: {},
			CustomDisabled: null,
			vModel: {}
		};
		this.ParameterEmptyObject = {};
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength100 = { maxLength: 100 };
		this.ParameterPosition = { ParameterCode: "Position" };
		this.ParameterTitle = { ParameterCode: "Title" };

		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ keyContactModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={ this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Position",
				accessor: "Position.ParameterDesc"
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Middle Name",
				accessor: "MidName"
			},
			{
				Header: "Last Name",
				accessor: "SurName"
			},
			{
				Header: "Phone",
				accessor: "Phone",
				Cell: row => (<span>{row.original.Phone == null ? "" : row.original.Phone.PhoneCountryCode == null ? "" : "+" + row.original.Phone.PhoneCountryCode.Code + " " + row.original.Phone.PhoneNumber}</span>)
			},
			{
				Header: "MobilePhone",
				accessor: "MobilePhone",
				Cell: row => (<span>{row.original.MobilePhone == null ? "" : row.original.MobilePhone.PhoneCountryCode == null ? "" : "+" + row.original.MobilePhone.PhoneCountryCode.Code + " " + row.original.MobilePhone.PhoneNumber}</span>)
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "DateOfBirth",
				accessor: "DateOfBirth"
			},
			{
				Header: "Occupation",
				accessor: "Occupation"
			}
		];
		this.KeyServiceContactData = [
			
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Last Name",
				accessor: "SurName"
			},
			{
				Header: "Fraud Service",
				accessor: "CustomerKeyContactServiceMap.FraudService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.FraudService == true ? "true" : "false"}</span>)
			},
			{
				Header: "New Product Service",
				accessor: "CustomerKeyContactServiceMap.NewProductService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.NewProductService == true ? "true" : "false"}</span>)
			},
			{
				Header: "System Maintenance Service",
				accessor: "CustomerKeyContactServiceMap.SystemMaintenanceService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.SystemMaintenanceService == true ? "true" : "false"}</span>)
			},
			{
				Header: "Invoice Settlement Service",
				accessor: "CustomerKeyContactServiceMap.InvoiceSettlementService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.InvoiceSettlementService == true ? "true" : "false"}</span>)
			},
			{
				Header: "Contract Service",
				accessor: "CustomerKeyContactServiceMap.ContractService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.ContractService == true ? "true" : "false"}</span>)
			},
			{
				Header: "Account Access Service",
				accessor: "CustomerKeyContactServiceMap.AccountAccessService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.AccountAccessService == true ? "true" : "false"}</span>)
			},
			{
				Header: "Compliance Service",
				accessor: "CustomerKeyContactServiceMap.ComplianceService",
				Cell: row => (<span>{row.original.CustomerKeyContactServiceMap.ComplianceService == true ? "true" : "false"}</span>)
			}
		];

	}

	RenderCountrySelect(d) {
		return "+" + d.Code + " (" + d.Name + ")";
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Key Contact");


		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add key contact person.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ keyContactModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedKeyContact, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));

	}
	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const global = { ...this.props.paramGlobalModel };
		const model = this.state.keyContactModel;
		const Phone = this.state.PrimaryPhoneModel;
		const MobilePhone = this.state.MobilePhoneModel;
		const ServiceMap = this.state.ServiceMapModel;

		if (global == null || global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add.");
		}
		if (model == null) {
			return;
		}
		if (model.Name == null || model.Name == "") {
			vModel.Name = true;
			ErrorList.push("Name must be defined !.");
		} else { vModel.Name = false; }
		if (model.SurName == null || model.SurName == "") {
			vModel.SurName = true;
			ErrorList.push("Last Name must be defined !.");
		} else { vModel.SurName = false; }
		if (model.Email == null || model.Email == "") {
			vModel.Email = true;
			ErrorList.push("Email must be defined !.");
		} else { vModel.Email = false; }
		if (Phone == null) {
			ErrorList.push("Phone not defined !.");
		}
		if (Phone == null || Phone.PhoneCountryCodeId == null || Phone.PhoneCountryCodeId == "") {
			vModel.PhoneCountryCodeId = true;
			ErrorList.push("Primary Phone Country Code must be defined !.");
		} else { vModel.PhoneCountryCodeId = false; }
		if (Phone == null || Phone.PhoneNumber == null || Phone.PhoneNumber == "") {
			vModel.PhoneNumber = true;
			ErrorList.push("Primary Phone Number must be defined !.");
		} else { vModel.PhoneNumber = false; }
		if (Phone != null && Phone.PhoneNumber != null) {
			if (Phone.PhoneNumber.toString().length != 10) {
				ErrorList.push("Primary Phone Number must be 10 Digits !.")
			}
		}


		// if (MobilePhone != null && (MobilePhone.PhoneNumber != null || MobilePhone.PhoneCountryCodeId != null)) {
		// 	if (MobilePhone.PhoneNumber != null && MobilePhone.PhoneNumber.toString().length != 10) {
		// 		ErrorList.push("Mobile phone number must be 10 Digits !.")
		// 	}
		// 	if (MobilePhone == null || MobilePhone.PhoneCountryCodeId == null || MobilePhone.PhoneCountryCodeId == "") {
		// 		vModel.PhoneCountryCodeId = true;
		// 		ErrorList.push("Mobile phone country code must be defined !.");
		// 	} else { vModel.PhoneCountryCodeId = false; }
		// 	if (MobilePhone == null || MobilePhone.PhoneNumber == null) {
		// 		vModel.PhoneNumber = true;
		// 		ErrorList.push("Mobile phone number must be defined !.");
		// 	} else { vModel.PhoneNumber = false; }
		// }



		if (model.Email != null && !this.ValidateEmail(model.Email)) {
			ErrorList.push("E-mail must be valid !");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ keyContactModel: model, PrimaryPhoneModel: { Id: 0 }, MobilePhoneModel: { Id: 0 }, ServiceMapModel: { Id: 0 }, vModel: {} });
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.keyContactModel || {};
			model[name] = newValue;
			return { keyContactModel: model };
		});
	}
	handleChangePrimaryPhone = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.PrimaryPhoneModel || {};
			model[name] = newValue;
			return { PrimaryPhoneModel: model };
		});
	}
	handleChangeMobilePhone = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.MobilePhoneModel || {};
			model[name] = newValue;
			return { MobilePhoneModel: model };
		});
	}
	handleChangeServiceMap = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.ServiceMapModel || {};
			model[name] = newValue;
			return { ServiceMapModel: model };
		});
	}
	UpdateModel = () => {
		var temp = this.state.keyContactModel;
		temp.Phone = this.state.PrimaryPhoneModel;
		temp.MobilePhone = this.state.MobilePhoneModel;
		temp.CustomerKeyContactServiceMap = this.state.ServiceMapModel;
		
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerKeyContact/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Contact Person  Updated Successfully !" : "Contact Person  Saved  Successfully! ");
				this.ClearData();
				this.props.GetCustomerKeyContactModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Contact Person Must Be Selected For The Delete Operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerKeyContact/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Contact Person Deleted Successfully !");
				this.ClearData();
				this.props.GetCustomerKeyContactModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerKeyContactList[index];
		this.setState({
			keyContactModel: model,
			PrimaryPhoneModel: model.Phone || {},
			MobilePhoneModel: model.MobilePhone || {},
			ServiceMapModel: model.CustomerKeyContactServiceMap || {}
		});
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { keyContactModel, MobilePhoneModel, PrimaryPhoneModel, ServiceMapModel, header, isLoading, vModel } = this.state;
		const { Disabled: DisabledProp, paramBankCustomerKeyContactList } = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<GridItem xs={12}>
					<LoadingComponent Show={isLoading} />

					<Card className="no-radius">
						{header &&
							<CardHeader >
								<h6><b>Client Contact</b></h6>
							</CardHeader>
						}
						<CardBody>
							<GridItem xs={12}>
								<GridContainer spacing={16} Title="Contact Information">
									<GridItem xs={4}>
										<GenericSelectInput
											CanClear
											Name="PositionId"
											LabelText="Position"
											Value={keyContactModel.PositionId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterPosition}
											Disabled={Disabled}
											DataRoot="Item" />
										
										<GenericSelectInput
											CanClear
											Name="TitleId"
											LabelText="Title"
											Value={keyContactModel.TitleId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterTitle}
											Disabled={Disabled}
											DataRoot="Item" />
										<GenericTextInput
											Disabled={Disabled}
											Name="Name"
											inputProps={this.ParameterMaxLength30}
											LabelText="Name"
											Value={keyContactModel.Name || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.Name} />
										<GenericTextInput
											Disabled={Disabled}
											Name="MidName"
											inputProps={this.ParameterMaxLength30}
											LabelText="Middle Name"
											Value={keyContactModel.MidName || ""}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={4} >
										<GenericTextInput
											Disabled={Disabled}
											Name="SurName"
											inputProps={this.ParameterMaxLength30}
											LabelText="Last Name"
											Value={keyContactModel.SurName || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.SurName}
										/>
										<GridContainer style={{ paddingLeft: "2px !important" }}>
											<GridItem xs={8} style={{ paddingLeft: "2px !important" }}>
												<GenericSelectInput
													Disabled={Disabled}
													Name="PhoneCountryCodeId"
													LabelText={"Primary Phone"}
													LabelMd={6}
													Method="POST"
													Url="/coreapi/v1.0/Country/GetAll"
													Parameter={this.ParameterEmptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Code"
													Value={PrimaryPhoneModel.PhoneCountryCodeId || ""}
													ValueChanged={this.handleChangePrimaryPhone}
													Sorted={this.ParameterCountrySortOrderIndex}
													RenderItem={this.RenderCountrySelect}
													Required
													IsInvalid={vModel.PhoneCountryCodeId}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericNumberInput
													Disabled={Disabled}
													Format="(###) ### ####"
													LabelMd={0}
													Name="PhoneNumber"
													Value={PrimaryPhoneModel.PhoneNumber || ""}
													ValueChanged={this.handleChangePrimaryPhone}
													Required
													IsInvalid={vModel.PhoneNumber}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={4}>
										<GridContainer style={{ paddingLeft: "2px !important" }}>
											<GridItem xs={8} style={{ paddingLeft: "2px !important" }}>
												<GenericSelectInput
													CanClear
													Disabled={Disabled}
													Name="PhoneCountryCodeId"
													LabelText={"Mobile Phone"}
													LabelMd={6}
													Method="POST"
													Url="/coreapi/v1.0/Country/GetAll"
													Parameter={this.ParameterEmptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Code"
													Value={MobilePhoneModel.PhoneCountryCodeId || ""}
													ValueChanged={this.handleChangeMobilePhone}
													Sorted={this.ParameterCountrySortOrderIndex}
													RenderItem={this.RenderCountrySelect}
												// Required
												// IsInvalid={vModel.PhoneCountryCodeId}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericNumberInput
													Disabled={Disabled}
													Format="(###) ### ####"
													LabelMd={0}
													Name="PhoneNumber"
													Value={MobilePhoneModel.PhoneNumber || ""}
													ValueChanged={this.handleChangeMobilePhone}
												// Required
												// IsInvalid={vModel.PhoneNumber}
												/>
											</GridItem>
										</GridContainer>
										<GenericEmailInput
											Disabled={Disabled}
											inputProps={this.ParameterMaxLength100}
											Name="Email"
											LabelText="E-Mail"
											Value={keyContactModel.Email || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.Email}
										/>
										<GenericDateInput
											Utc
											Disabled={Disabled}
											IsPast={true}
											Name="DateOfBirth"
											LabelText="Date Of Birth"
											Value={keyContactModel.DateOfBirth == null ? "" : DateHelper.ToDateInputValue(keyContactModel.DateOfBirth)}
											ValueChanged={this.handleChange} />
										<GenericTextInput
											Disabled={Disabled}
											Name="Occupation"
											inputProps={this.ParameterMaxLength30}
											LabelText="Occupation"
											Value={keyContactModel.Occupation || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.Occupation}/>
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16} Title="Service Email">
									<GridItem xs={4}>
										<GenericCheckInput
											Disabled={Disabled}
											Name="ComplianceService"
											LabelText="Compliance Service"
											Value={ServiceMapModel.ComplianceService || false}
											ValueChanged={this.handleChangeServiceMap} />
											<br/>
										<GenericCheckInput
											Disabled={Disabled}
											Name="SystemMaintenanceService"
											LabelText="System Maintenance Service"
											Value={ServiceMapModel.SystemMaintenanceService || false}
											ValueChanged={this.handleChangeServiceMap} />
											<br/>
										<GenericCheckInput
											Disabled={Disabled}
											Name="InvoiceSettlementService"
											LabelText="Invoice Settlement Service"
											Value={ServiceMapModel.InvoiceSettlementService || false}
											ValueChanged={this.handleChangeServiceMap} />
									</GridItem>
									<GridItem xs={4}>
										<GenericCheckInput
											Disabled={Disabled}
											Name="ContractService"
											LabelText="Contract Service"
											Value={ServiceMapModel.ContractService || false}
											ValueChanged={this.handleChangeServiceMap} />
										<br/>
										<GenericCheckInput
											Disabled={Disabled}
											Name="AccountAccessService"
											LabelText="Account Access Service"
											Value={ServiceMapModel.AccountAccessService || false}
											ValueChanged={this.handleChangeServiceMap} />
									</GridItem>
									<GridItem xs={4}>
										<GenericCheckInput
											Disabled={Disabled}
											Name="FraudService"
											LabelText="Fraud Service"
											Value={ServiceMapModel.FraudService || false}
											ValueChanged={this.handleChangeServiceMap} />
										<br/>
										<GenericCheckInput
											Disabled={Disabled}
											Name="NewProductService"
											LabelText="New Product Service"
											Value={ServiceMapModel.NewProductService || false}
											ValueChanged={this.handleChangeServiceMap} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{keyContactModel.Id > 0 ? "Update" : "Add"}</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>

					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={paramBankCustomerKeyContactList}
										PageSize={5}
										HideButton={true}
										Columns={this.CloumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={paramBankCustomerKeyContactList}
										PageSize={5}
										HideButton={true}
										Columns={this.KeyServiceContactData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

KeyContact.propTypes = {
	classes: PropTypes.object,
	keyContactModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramBankCustomerKeyContactList: PropTypes.array,
	paramBankCustomerExternalAccountList: PropTypes.array,
	GetCustomerKeyContactModels: PropTypes.func
};


export default withArtifex(KeyContact, {});