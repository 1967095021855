import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, MenuCodes } from "views/Constants/Constant.js";
import moment from "moment";

class CorporateDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			alert: null,
			isLoading: false,
			CustomDisabled: null
		};

		this.ParameterClientStatus = { ParameterCode: "CustomerStatus" };
		this.ParameterTitle = { ParameterCode: "Title" };
		this.ParameterEmptyObject = {};
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.ParameterMaxLength50 = { maxLength: 50 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.ParameterMaxLength60 = { maxLength: 60 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength15 = { maxLength: 15 };
		this.ParameterMaxLength10 = { maxLength: 10 };
		this.ParameterMaxLength9 = { maxLength: 25 };
		this.ParameterProvince = { ParameterCode: "Province" };
		this.ParameterLicenseRegistrationType = { ParameterCode: "LicenseRegistrationType" };
		this.ParameterLicenseRegistrationEntity = { ParameterCode: "LicenseRegistrationEntity" };
		this.ParameterLanguage = { ParameterCode: "Language" };
		this.ParameterYesNo = { ParameterCode: "YesNo" };
		this.ParameterEntityType = { ParameterCode: "EntityType" };
		this.ParameterBusinessNature = { ParameterCode: "BusinessNature" };
		this.ParameterIndustryOfEntity = { ParameterCode: "IndustryOfEntity" };
		this.ParameterFATCA = { ParameterCode: "FATCA" };
	}
	RenderCountrySelect(d) {
		return "+" + d.Code + " (" + d.Name + ")";
	}
	CustomHandleChange = (n, v, d) => {
		this.props.handleChangeCorporate(n, v == "true");
	}
	componentDidMount() {
		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedCorporate, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}

	HandleIsCertificateExpired(date){
		const compareDate = DateHelper.ToDateInputValue(date);
		const oneYearAgo = DateHelper.GetDateLocal().add(-1, "years");
		return !moment.isMoment(compareDate) ? true : compareDate.isBefore(oneYearAgo);
	}

	OperationCheck = () => {
		var ErrorList = [];
		const vModel = this.state.vModel;
		const global = { ...this.props.paramGlobalModel };
		const model = this.props.paramBankCustomerCorporate;
		const paramCorporateFaxPhone = this.props.paramCorporateFaxPhone;

		if (model == null) {
			return;
		}

		paramCorporateFaxPhone.IsPhoneNumber = false;

		if (model.LastEffectivenessDate == null || model.LastEffectivenessDate == "") {

			model.LastEffectivenessDate = null;
		}
		if (model.LastFintracDate == null || model.LastFintracDate == "") {

			model.LastFintracDate = null;
		}

		if ((paramCorporateFaxPhone.PhoneCountryCodeId != null) && (paramCorporateFaxPhone.PhoneNumber == null || paramCorporateFaxPhone.PhoneNumber == "")) {
			paramCorporateFaxPhone["IsPhoneNumber"] = true;
			ErrorList.push("Fax Phone number must be defined !.");
		}

		if ((global == null || global.Id == null) && !global.CheckNewClient) {
			ErrorList.push("You must select a customer or create new one to proceed.");
		}
		if (global.ClientType == "" || global.ClientType == null) {
			ErrorList.push("You must select the customer type");
		}

		if (model.ClientStatusId == null || model.ClientStatusId == "") {
			vModel.ClientStatusId = true;
			ErrorList.push("Client Status must be defined !.");
		} else { vModel.ClientStatusId = false; }



		if (model.LegalName == null || model.LegalName == "") {
			vModel.LegalName = true;
			ErrorList.push("Legal Name must be defined !.");
		} else { vModel.LegalName = false; }

		if (model.ResidencyForTaxCountryId == null || model.ResidencyForTaxCountryId == "") {
			vModel.ResidencyForTaxCountryId = true;
			ErrorList.push("Residency for Tax Purposes (Country) !.");
		} else { vModel.ResidencyForTaxCountryId = false; }

		if (model.ResidencyForTaxStateId == null || model.ResidencyForTaxStateId == "") {
			vModel.ResidencyForTaxStateId = true;
			ErrorList.push("Residency for Tax Purposes (Province or State) !.");
		} else { vModel.ResidencyForTaxStateId = false; }

		if (model.IncorporationCountryId == null || model.IncorporationCountryId == "") {
			vModel.IncorporationCountryId = true;
			ErrorList.push("Incorporation Country must be defined !.");
		} else { vModel.IncorporationCountryId = false; }


		if (model.BusinessPhone == null || model.BusinessPhone.PhoneCountryCodeId == null || model.BusinessPhone.PhoneCountryCodeId == "") {
			vModel.PhoneCountryCodeId = true;
			ErrorList.push("Business Phone country code must be defined !.");
		} else { vModel.PhoneCountryCodeId = false; }

		if (model.BusinessPhone == null || model.BusinessPhone.PhoneNumber == null || model.BusinessPhone.PhoneNumber == "") {
			vModel.PhoneNumber = true;
			ErrorList.push("Business Phone must be defined !.");
		} else { vModel.PhoneNumber = false; }

		if (model.Email == null || model.Email == "") {
			vModel.Email = true;
			ErrorList.push("Email must be defined !.");
		} else { vModel.Email = false; }

		if (model.PreferedLanguageId == null || model.PreferedLanguageId == "") {
			vModel.PreferedLanguageId = true;
			ErrorList.push("Preferred Language must be defined !.");
		} else { vModel.PreferedLanguageId = false; }

		if (model.SendSms == null) {
			vModel.SendSms = true;
			ErrorList.push("Text/SMS Notification must be defined !.");
		} else { vModel.SendSms = false; }

		if (model.SendEmail == null) {
			vModel.SendEmail = true;
			ErrorList.push("E-Mail Notification must be defined !.");
		} else { vModel.SendEmail = false; }

		if (model.EntityTypeId == null || model.EntityTypeId == "") {
			vModel.EntityTypeId = true;
			ErrorList.push("Entity Type must be defined !.");
		} else { vModel.EntityTypeId = false; }

		if (model.BusinessNatureId == null || model.BusinessNatureId == "") {
			vModel.BusinessNatureId = true;
			ErrorList.push("Business Nature  must be defined !.");
		} else { vModel.BusinessNatureId = false; }

		if (model.NAICId == null || model.NAICId == "") {
			vModel.NAICId = true;
			ErrorList.push("Entity Industry must be defined !.");
		} else { vModel.NAICId = false; }

		if (model.PublicityTradeEntity == null) {
			vModel.PublicityTradeEntity = true;
			ErrorList.push("Publicly Trade Entity must be defined !.");
		} else { vModel.PublicityTradeEntity = false; }

		if (model.WebSite == null || model.WebSite == "") {
			vModel.WebSite = true;
			ErrorList.push("WebSite must be defined !.");
		} else { vModel.WebSite = false; }
		if (model.EntityTypeId == null || model.EntityTypeId == "") {
			vModel.EntityTypeId = true;
			ErrorList.push("EntityTypeId must be defined !.");
		} else { vModel.EntityTypeId = false; }
		// if (model.NAIC == null || model.NAIC == "") {
		// 	vModel.NAIC = true;
		// 	ErrorList.push("Industry Code must be defined !.");
		// } else { vModel.NAIC = false; }
		// if (model.StreetName == null || model.StreetName == "") {
		// 	vModel.StreetName = true;
		// 	ErrorList.push("StreetName must be defined !.");
		// } else { vModel.StreetName = false; }
		// if (model.StreetNumber == null || model.StreetNumber == "") {
		// 	vModel.StreetNumber = true;
		// 	ErrorList.push("StreetNumber must be defined !.");
		// } else { vModel.StreetNumber = false; }
		// if (model.City == null || model.City == "") {
		// 	vModel.City = true;
		// 	ErrorList.push("City must be defined !.");
		// } else { vModel.City = false; }
		// if (model.Province == null || model.Province == "") {
		// 	vModel.Province = true;
		// 	ErrorList.push("Province must be defined !.");
		// } else { vModel.Province = false; }
		// if (model.PostalCode == null || model.PostalCode == "") {
		// 	vModel.PostalCode = true;
		// 	ErrorList.push("PostalCode must be defined !.");
		// } else { vModel.PostalCode = false; }
		
		if (model.Email != null && !this.ValidateEmail(model.Email)) {

			ErrorList.push("E-mail must be valid !");
		}
		if (model != null && model.FaxPhone != null && (model.FaxPhone.PhoneNumber != null || model.FaxPhone.PhoneCountryCodeId != null)) {
			if (model.FaxPhone.PhoneNumber != null && model.FaxPhone.PhoneNumber.toString().length > 10) {
				ErrorList.push("Fax Phone Number must not be empty or greater than 10 Digits !.")
			}
			if (model.FaxPhone == null || model.FaxPhone.PhoneCountryCodeId == null || model.FaxPhone.PhoneCountryCodeId == "") {
				vModel.FaxPhone = true;
				ErrorList.push("Fax Phone country code must be defined !.");
			} else { vModel.FaxPhone = false; }
			if (model.FaxPhone == null || model.FaxPhone.PhoneNumber == null) {
				ErrorList.push("Fax Phone number must be defined !.");
			}
		}
		if (model != null && model.BusinessPhone != null && model.BusinessPhone.PhoneNumber != null) {
			if (model.BusinessPhone.PhoneNumber.toString().length > 10) {
				ErrorList.push("Business Phone Number must not be greater than 10 Digits !.")
			}
		}

		if (String(model.PciCertification) == "true" && this.HandleIsCertificateExpired(model.PciIssuanceDate)){
			ErrorList.push("PCI Certification expired! Select PCI Certification 'No' or set an actual date!");
		} 
		if(String(model.PciCertification) == "false"){ 
			model.PciIssuanceDate = null; 
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 25).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateCorporate();
	}

	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	FilterEntityType = (value) => value.ParameterValue6 == 'Actual';
	
	UpdateCorporate = () => {

		const prop = { ...this.props.paramGlobalModel };
		var data = this.props.paramBankCustomerCorporate;

		if (prop.CheckNewClient) {
			data.ClientType = prop.ClientType;
		}
		data.OrganizationUniqueId = prop.OrganizationUniqueId;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerCorporate/Update",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					if (responseData.ErrorCode != "MerchantInsE3214") {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
					}
					else {
						this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
					}
					return;
				}
				if (responseData.Item.InteracErrorList != null && responseData.Item.InteracErrorList.length > 0) {
					var operationResult = data.Id > 0 ? "Client Profile Updated !" : "Client Profile Saved ! ";
					this.ShowMessageNode("warning", operationResult + "But Interac Update Integration Error", responseData.Item.InteracErrorList);
				}
				else
					this.ShowMessage("success", "Success", data.Id > 0 ? "Client Profile Updated !" : "Client Profile Saved ! ");

				this.props.setCorporateState(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);

	}

	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { paramBankCustomerCorporate, handleChangeCorporate, handleChangeCorporatePhoneFax, handleChangeCorporatePhoneBuss, paramCorporateBussinesPhone, paramCorporateFaxPhone } = this.props;
		const { Disabled: DisabledProp, } = this.props;
		const vModel = this.state.vModel;

		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<Card className="no-radius">
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<CardBody>
					<GridContainer>
						<GridItem xs={4}>
							<h5><b>Corporate Wallet</b></h5>
							<GenericTextInput
								Disabled={Disabled}
								LabelMd={6}
								inputProps={this.ParameterMaxLength60}
								Name="LegalName"
								LabelText="Legal Name of Business"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LegalName}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.LegalName}
							/>
							<GenericTextInput
								Disabled={Disabled}
								LabelMd={6}
								inputProps={this.ParameterMaxLength30}
								Name="DoingBusiness"
								LabelText="Doing Business As"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.DoingBusiness}
								ValueChanged={handleChangeCorporate}
							/>
							<GenericTextInput
								Disabled={Disabled}
								LabelMd={6}
								inputProps={this.ParameterMaxLength9}
								Name="BusinessIdNumber"
								LabelText="Business ID Nr. (BIN)"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.BusinessIdNumber}
								ValueChanged={handleChangeCorporate} />
							<GenericTextInput
								Disabled={Disabled}
								LabelMd={6}
								inputProps={this.ParameterMaxLength10}
								Name="QuebecEntNumber"
								LabelText="Quebec EnT. Number (NEQ)"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.QuebecEntNumber}
								ValueChanged={handleChangeCorporate} />
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="ResidencyForTaxCountryId"
								LabelText="Residency Tax (Country)"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={this.ParameterEmptyObject}
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.ResidencyForTaxCountryId}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.ResidencyForTaxCountryId}
							/>
							<GenericSelectInput
								key={paramBankCustomerCorporate.ResidencyForTaxCountryId }
								Disabled={Disabled}
								LabelMd={6}
								Name="ResidencyForTaxStateId"
								LabelText="Residency Tax (Province or State)"
								Method="POST"
								Url="/coreapi/v1.0/Province/Filter"
								Parameter={{ CountryId: paramBankCustomerCorporate.ResidencyForTaxCountryId }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.ResidencyForTaxStateId}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.ResidencyForTaxStateId}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								Name="IncorporationCountryId"
								LabelText="Country Incorporation"
								LabelMd={6}
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={this.ParameterEmptyObject}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember={"Name"}
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.IncorporationCountryId}
								ValueChanged={handleChangeCorporate}
								Sorted={this.ParameterCountrySortOrderIndex}
								Required
								IsInvalid={vModel.IncorporationCountryId}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="LicenseRegistrationTypeId"
								LabelText="License Registration Type 1"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterLicenseRegistrationType}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationTypeId}
								ValueChanged={handleChangeCorporate}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="LicenseRegistrationEntityId"
								LabelText="License Registration Entity 1"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterLicenseRegistrationEntity}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityId}
								ValueChanged={handleChangeCorporate}
							/>
							{(paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther != null) && (
								<GenericTextInput
									Disabled={Disabled}
									LabelMd={6}
									inputProps={this.ParameterMaxLength30}
									Name="LicenseRegistrationEntityOther"
									LabelText="License Registration Entity Other 1"
									Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityOther}
									ValueChanged={handleChangeCorporate}
								/>
							)}
							<GenericTextInput
								Disabled={Disabled || (paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther != null)}
								LabelMd={6}
								inputProps={this.ParameterMaxLength30}
								Name="LicenseRegistrationNumber"
								LabelText="License Registration No. 1"
								Value={paramBankCustomerCorporate == null || paramBankCustomerCorporate.LicenseRegistrationEntityOther != null ? "" : paramBankCustomerCorporate.LicenseRegistrationNumber}
								ValueChanged={handleChangeCorporate}
							/>
							{(paramBankCustomerCorporate.LicenseRegistrationTypeId != null && paramBankCustomerCorporate.LicenseRegistrationEntityId != null && (paramBankCustomerCorporate.LicenseRegistrationNumber || paramBankCustomerCorporate.LicenseRegistrationEntityOther)) && (
								<div>

									<GenericSelectInput
										Disabled={Disabled}
										LabelMd={6}
										Name="LicenseRegistrationTypeId2"
										LabelText="License Registration Type 2"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterLicenseRegistrationType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationTypeId2}
										ValueChanged={handleChangeCorporate}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										LabelMd={6}
										Name="LicenseRegistrationEntityId2"
										LabelText="License Registration Entity 2"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterLicenseRegistrationEntity}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityId2}
										ValueChanged={handleChangeCorporate}
									/>
									{(paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther2 != null) && (
										<GenericTextInput
											Disabled={Disabled}
											LabelMd={6}
											inputProps={this.ParameterMaxLength30}
											Name="LicenseRegistrationEntityOther2"
											LabelText="License Registration Entity Other 2"
											Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityOther2}
											ValueChanged={handleChangeCorporate}
										/>
									)}
									<GenericTextInput
										Disabled={Disabled || (paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther2 != null)}
										LabelMd={6}
										inputProps={this.ParameterMaxLength30}
										Name="LicenseRegistrationNumber2"
										LabelText="License Registration No. 2"
										Value={paramBankCustomerCorporate == null || paramBankCustomerCorporate.LicenseRegistrationEntityOther2 != null ? "" : paramBankCustomerCorporate.LicenseRegistrationNumber2}
										ValueChanged={handleChangeCorporate}
									/>
								</div>
							)}
							{(paramBankCustomerCorporate.LicenseRegistrationTypeId2 != null && paramBankCustomerCorporate.LicenseRegistrationEntityId2 != null && (paramBankCustomerCorporate.LicenseRegistrationNumber2 || paramBankCustomerCorporate.LicenseRegistrationEntityOther2)) && (
								<div>

									<GenericSelectInput
										Disabled={Disabled}
										LabelMd={6}
										Name="LicenseRegistrationTypeId3"
										LabelText="License Registration Type 3"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterLicenseRegistrationType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationTypeId3}
										ValueChanged={handleChangeCorporate}
									/>
									<GenericSelectInput
										Disabled={Disabled}
										LabelMd={6}
										Name="LicenseRegistrationEntityId3"
										LabelText="License Registration Entity 3"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterLicenseRegistrationEntity}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityId3}
										ValueChanged={handleChangeCorporate}
									/>
									{(paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther3 != null) && (
										<GenericTextInput
											Disabled={Disabled}
											LabelMd={6}
											inputProps={this.ParameterMaxLength30}
											Name="LicenseRegistrationEntityOther3"
											LabelText="License Registration Entity Other 3"
											Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LicenseRegistrationEntityOther3}
											ValueChanged={handleChangeCorporate} />
									)}
									<GenericTextInput
										Disabled={Disabled || (paramBankCustomerCorporate != null && paramBankCustomerCorporate.LicenseRegistrationEntityOther3 != null)}
										LabelMd={6}
										inputProps={this.ParameterMaxLength30}
										Name="LicenseRegistrationNumber3"
										LabelText="License Registration No. 3"
										Value={paramBankCustomerCorporate == null || paramBankCustomerCorporate.LicenseRegistrationEntityOther3 != null ? "" : paramBankCustomerCorporate.LicenseRegistrationNumber3}
										ValueChanged={handleChangeCorporate}
									/>
								</div>
							)}
							<GenericSelectInput
								LabelMd={6}
								Disabled={Disabled}
								Name="ClientStatusId"
								LabelText="Client Status"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.ClientStatusId || ""}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								ValueChanged={handleChangeCorporate}
								Method="GET" Url="/bankapi/v1.0/ClientCustomer/GetFilteredCustomerStatuses"
								Parameter={this.ParameterClientStatus}
								DataRoot="Item"
								Required
								IsInvalid={vModel.ClientStatusId}
							/>
							
							{(paramBankCustomerCorporate.ClientStatusId != null && paramBankCustomerCorporate.ClientStatusId == 923028) && (
								<GenericDateInput
									LabelMd={6}
									Utc
									Disabled={true}
									IsPast={true}
									Name="ClosureDate"
									LabelText="Closure Date"
									Value={paramBankCustomerCorporate == null ? "" : DateHelper.ToMoment(paramBankCustomerCorporate.ClosureDate) || ""}
								/>)}
							
							<GenericDateInput
								LabelMd={6}
								Utc
								Disabled={true}
								IsPast={true}
								Name="InsertDateTime"
								LabelText="Create Date"
								Value={paramBankCustomerCorporate == null ? "" : DateHelper.ToMoment(paramBankCustomerCorporate.InsertDateTime) || ""}
							/>
							<GenericTextInput
								LabelMd={6}
								Disabled={true}
								Name="UpdateUser"
								LabelText="Employee ID"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.UpdateUser}
								ValueChanged={handleChangeCorporate} />
							<GenericDateInput
								LabelMd={6}
								Utc
								Disabled={true}
								IsPast={true}
								Name="UpdateDateTime"
								LabelText="Last Update Date"
								Value={paramBankCustomerCorporate == null ? "" : DateHelper.ToMoment(paramBankCustomerCorporate.UpdateDateTime) || ""}
							/>
                            <GenericSelectInput
                                LabelMd={6}
                                Name="PciCertification"
                                LabelText="PCI Certification"
                                Value={paramBankCustomerCorporate?.PciCertification == null ? "false" : String(paramBankCustomerCorporate.PciCertification)}
                                ValueChanged={handleChangeCorporate}
                                Parameter={this.ParameterYesNo}
                                KeyValueMember="ParameterValue"
                                TextValueMember="ParameterDesc"
                                Method="POST"
                                Url="/coreapi/v1.0/Parameter/Search"
                                DataRoot="Item"
                            />
                            { String(paramBankCustomerCorporate?.PciCertification) === "true" &&
                                <GenericDateInput
                                    LabelMd={6}
                                    Utc
                                    Name="PciIssuanceDate"
                                    IsInvalid={this.HandleIsCertificateExpired(paramBankCustomerCorporate.PciIssuanceDate)}
                                    Required={true}
                                    LabelText="PCI Certificate Issuance Date"
                                    Value={paramBankCustomerCorporate?.PciIssuanceDate == null ? "" : DateHelper.ToDateInputValue(paramBankCustomerCorporate.PciIssuanceDate)}
                                    MaxDate={DateHelper.GetDateLocal()}
                                    MinDate={DateHelper.GetDateLocal().add(-1, "years")}
                                    ValueChanged={handleChangeCorporate} /> }
						</GridItem>
						<GridItem xs={4}>
							<h5><b>Communication</b></h5>
							<GridContainer>
								<GridItem xs={8}>
									<GenericSelectInput
										Disabled={Disabled}
										Name="PhoneCountryCodeId"
										LabelText={"Business Phone"}
										LabelMd={6}
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.ParameterEmptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Code"
										Value={paramCorporateBussinesPhone == null ? "" : paramCorporateBussinesPhone.PhoneCountryCodeId || ""}
										ValueChanged={handleChangeCorporatePhoneBuss}
										Sorted={this.ParameterCountrySortOrderIndex}
										RenderItem={this.RenderCountrySelect}
										Required
										IsInvalid={vModel.PhoneCountryCodeId}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										Disabled={Disabled}
										Format={"(###) ### ####"}
										LabelMd={0}
										Name="PhoneNumber"
										Value={paramCorporateBussinesPhone == null ? "" : paramCorporateBussinesPhone.PhoneNumber || ""}
										ValueChanged={handleChangeCorporatePhoneBuss}
										Required
										IsInvalid={vModel.PhoneNumber}

									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={8}>
									<GenericSelectInput
										CanClear
										Disabled={Disabled}
										Name="PhoneCountryCodeId"
										LabelText={"Fax Phone"}
										LabelMd={6}
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.ParameterEmptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Code"
										Value={paramCorporateFaxPhone == null ? "" : paramCorporateFaxPhone.PhoneCountryCodeId || ""}
										ValueChanged={handleChangeCorporatePhoneFax}
										Sorted={this.ParameterCountrySortOrderIndex}
										RenderItem={this.RenderCountrySelect}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										Disabled={Disabled}
										Format={"(###) ### ####"}
										LabelMd={0}
										Name="PhoneNumber"
										Value={paramCorporateFaxPhone == null ? "" : paramCorporateFaxPhone.PhoneNumber || ""}
										ValueChanged={handleChangeCorporatePhoneFax}
										Required={paramCorporateFaxPhone.PhoneCountryCodeId ? true : false}
										IsInvalid={paramCorporateFaxPhone.IsPhoneNumber}
									/>
								</GridItem>
							</GridContainer>
							<GenericEmailInput
								Disabled={Disabled}
								inputProps={this.ParameterMaxLength64}
								Name="Email"
								LabelText="E-Mail"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.Email}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.Email}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								Name="PreferedLanguageId"
								LabelText="Preferred Language"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterLanguage}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.PreferedLanguageId}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.PreferedLanguageId}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								Name="SendSms"
								LabelText="Text/SMS Notification"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterYesNo}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.SendSms)}
								ValueChanged={this.CustomHandleChange}
								Required
								IsInvalid={vModel.SendSms}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								Name="SendEmail"
								LabelText="E-Mail Notification"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterYesNo}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.SendEmail)}
								ValueChanged={this.CustomHandleChange}
								Required
								IsInvalid={vModel.SendEmail}
							/>
						</GridItem>
						<GridItem xs={4}>
							<h5><b>Other</b></h5>
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="EntityTypeId"
								LabelText="Type of Entity"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterEntityType}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.EntityTypeId}
								FilterPredicate = {this.FilterEntityType}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.EntityTypeId}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="NAICId"
								LabelText="Industry of Entity"
								Method="GET"
								Url="/bankapi/v1.0/CustomerCorporate/GetNaicList/OrganizationId?OrganizationId=5002"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="SecondLevel"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.NAICId}
								ValueChanged={handleChangeCorporate}
								Required
								IsInvalid={vModel.NAICId}
							/>
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="IsAccountUsedByTp"
								LabelText="Wallet Used By Tp"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterYesNo}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.IsAccountUsedByTp)}
								ValueChanged={this.CustomHandleChange}
							/>
							<GenericTextInput
								LabelMd={6}
								Disabled={true}
								Name="AccountRiskRating"
								LabelText="Wallet Risk Rating"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.AccountRiskRating}
								ValueChanged={handleChangeCorporate} />
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="PublicityTradeEntity"
								LabelText="Publicly Trade Entity"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterYesNo}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.PublicityTradeEntity)}
								ValueChanged={this.CustomHandleChange}
								Required
								IsInvalid={vModel.PublicityTradeEntity}
							/>
							<GenericDateInput
								Disabled={Disabled}
								LabelMd={6}
								IsPast={true}
								Name="LastEffectivenessDate"
								LabelText="Date of Last Effectiveness Review"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LastEffectivenessDate == null ? "" : DateHelper.ToDateInputValue(paramBankCustomerCorporate.LastEffectivenessDate)}
								ValueChanged={handleChangeCorporate} />
							<GenericSelectInput
								Disabled={Disabled}
								LabelMd={6}
								Name="FatcaId"
								LabelText="FATCA"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterFATCA}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.FatcaId}
								ValueChanged={handleChangeCorporate}
							/>
							<GenericDateInput
								Disabled={Disabled}
								LabelMd={6}
								IsPast={true}
								Name="LastFintracDate"
								LabelText="Date of Last FINTRAC Review"
								Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.LastFintracDate == null ? "" : DateHelper.ToDateInputValue(paramBankCustomerCorporate.LastFintracDate)}
								ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="WebSite"
                                LabelText="Web Site"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.WebSite}
                                ValueChanged={handleChangeCorporate} />
                            <GenericSelectInput
                                LabelMd={6}
                                Disabled={Disabled}
                                Name="EntityTypeId"
                                LabelText="Corporation type"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.EntityTypeId || ""}
                                KeyValueMember="Id"
                                TextValueMember="ParameterDesc"
                                ValueChanged={handleChangeCorporate}
                                Method="GET" Url="/bankapi/v1.0/ClientCustomer/GetFilteredEntityType"
                                Parameter={"EntityType"}
                                DataRoot="Item"
                                Required
                                IsInvalid={vModel.EntityTypeId}/>
                            <GenericTextInput
                                LabelMd={6}
                                Disabled={false}
                                Name="SearchRefID"
                                LabelText="CUID"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.SearchRefID}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                LabelMd={6}
                                Disabled={false}
                                Name="AOBCustomer"
                                LabelText="AOB Customer"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.AOBCustomer}
                                ValueChanged={handleChangeCorporate} />

                           {/* <h5><b>Business Address</b></h5>
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="StreetName"
                                LabelText="Street Name"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.StreetName}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="StreetNumber"
                                LabelText="Street Number"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.StreetNumber}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="City"
                                LabelText="City"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.City}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="Province"
                                LabelText="Province"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.Province}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="Country"
                                LabelText="Country"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.Country}
                                ValueChanged={handleChangeCorporate} />
                            <GenericTextInput
                                Required
                                LabelMd={6}
                                Disabled={false}
                                Name="PostalCode"
                                LabelText="Postal Code"
                                Value={paramBankCustomerCorporate == null ? "" : paramBankCustomerCorporate.PostalCode}
                                ValueChanged={handleChangeCorporate} />
                            <GenericSelectInput
                                LabelMd={6}
                                IsStatic
                                StaticData={[{ Value: "No" }, { Value: "Yes" }]}
                                Name="PciCertification"
                                LabelText="PCI Certification"
                                Parameter={this.ParameterYesNo}
                                KeyValueMember="Value"
                                TextValueMember="Value"
                                Value={paramBankCustomerCorporate?.PciCertification == null ? "No" : paramBankCustomerCorporate.PciCertification}
                                ValueChanged={handleChangeCorporate}
                            />*/}
                            {(paramBankCustomerCorporate.ClientStatusId != null && paramBankCustomerCorporate.ClientStatusId == 923028) && (
                                <GenericDateInput
                                    LabelMd={6}
                                    Utc
                                    Disabled={true}
                                    IsPast={true}
                                    Name="ClosureDate"
                                    LabelText="Closure Date"
                                    Value={paramBankCustomerCorporate == null ? "" : DateHelper.ToMoment(paramBankCustomerCorporate.ClosureDate) || ""}
                                />)}
						</GridItem>
					</GridContainer>
					<GridContainer justify="flex-end">
						<GridItem style={{ float: "right" }}>
							<Button size="sm" disabled={((this.props.paramGlobalModel.OrganizationUniqueId == null || this.props.paramGlobalModel.OrganizationUniqueId == 0) ? true : false) || Disabled || this.props.paramGlobalModel.ClientType == null ? true : this.props.paramGlobalModel.ClientType == "" ? true : false} color="black" onClick={() => this.OperationCheck()} > Save </Button>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card >

		);
	}
}

CorporateDetails.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	handleChangeChildTab: PropTypes.func,
	paramBankCustomerCorporate: PropTypes.object,
	handleChangeCorporate: PropTypes.func,
	handleChangeCorporatePhoneFax: PropTypes.func,
	handleChangeCorporatePhoneBuss: PropTypes.func,
	paramCorporateBussinesPhone: PropTypes.object,
	paramCorporateFaxPhone: PropTypes.object,
	setCorporateState: PropTypes.func
};


export default withArtifex(CorporateDetails, {});